var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[(_vm.contract)?_c('div',{class:['box box-solid', {'bg-transparent': _vm.isCustomTab}]},[_c('div',{staticClass:"nav-tabs-custom"},[(_vm.defaultTab)?[_c('ul',{staticClass:"nav nav-tabs ui-sortable-handle",class:_vm.$utils.isMobile() ? 'mobile-tab' : 'desktop-tab'},[_vm._l((_vm.tabs),function(item,index){return _c('li',{key:index,class:{active: item.href == _vm.$route.path},attrs:{"title":_vm.$tc(item.title, 2)}},[_c('router-link',{attrs:{"to":item.href}},[_c('Icon',{attrs:{"title":null,"name":(!item.iconClass.includes('fa ') &&
                  item.iconClass.includes('fa-')
                    ? 'fa '
                    : '') + item.iconClass}})],1)],1)}),(!_vm.$utils.isMobile())?_c('PanelHeaderEquipmentList',{attrs:{"title":_vm.title,"nItems":_vm.nItems}}):_vm._e()],2),_c('div',{staticClass:"tab-content no-padding"},[_c('div',{staticClass:"tab-pane active no-padding",staticStyle:{"position":"relative"}},[(
                _vm.hasPermission((_vm.currentTab || {}).rule) ||
                  (_vm.currentTab && !_vm.currentTab.rule)
              )?_c('router-view',{attrs:{"filterCriteria":_vm.filterCriteria,"display":_vm.display},on:{"nItemsChanged":_vm.onItemsChanged,"finished":function($event){return _vm.onFinished($event)},"fetchEquipmentList":function($event){return _vm.fetchEquipmentList()}}}):(_vm.currentTab)?_c('InfoBox',{attrs:{"preset":"unauthorized"}}):_vm._e()],1),(_vm.isEmpty && (!_vm.nItems || !_vm.nItems.available))?_c('div',{staticClass:"container text-center big-box"},[_c('div',{staticClass:"box box-solid no-padding"},[_c('div',{staticClass:"alert alert-default"},[(
                    _vm.$can('manage', 'EquipamentoCadastro') &&
                      (!_vm.nItems || !_vm.nItems.available)
                  )?_c('div',{staticClass:"btn-action"},[_c('router-link',{attrs:{"to":"/dashboard/edit/connector/0#edit"}},[_c('div',{staticClass:"btn btn-primary btn-lg"},[_vm._v(" "+_vm._s(_vm.$t("titles.add_your_first", { item: _vm.$tc("connector", 1) }))+" ")])])],1):_vm._e()])])]):_vm._e()])]:[_c('InfoBox',{attrs:{"preset":"unauthorized","dismissible":true},on:{"close":function($event){return _vm.$store.dispatch('user/logout')}}})]],2),(_vm.isLoading)?_c('div',{staticClass:"overlay"},[_c('i',{staticClass:"fa fa-refresh fa-spin"})]):_vm._e()]):_vm._e(),_c('CustomActionManager',{attrs:{"connector":null}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }